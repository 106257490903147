import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Case, Default, Switch } from 'react-if'
import { Button } from '@/atoms/Button'
import { ParagraphSM } from '@/atoms/Text'
import { slugs } from '@/constants'
import { DOMESTIC_REGIONS } from '@/constants/regions'
import { useRegionContext } from '@/contexts/RegionContext'
import { ThemeContextProvider } from '@/contexts/ThemeContext'
import { AngelDialog } from '@/molecules/AngelDialog'
import { useUser } from '@/services/UserService'
import { useLocale } from '@/utils/LocaleUtil'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { EmailCaptureFormInput } from '@/views/SignUpView/EmailCapture/EmailCaptureFormInput/EmailCaptureFormInput'

const REGIONS_WITH_FULL_BENEFITS = new Set(DOMESTIC_REGIONS)
export const GuildGeogatedBenefitsPrompt = ({ slug }: { slug?: string }) => {
  const { locale } = useLocale()
  const { user } = useUser()
  const { t } = useTranslate('guild')
  const isDismissed = useRef<boolean>(false)
  const [isOpen, setOpen] = useState<boolean>(false)
  const [email, setEmail] = useState<string>(user?.email ?? '')
  const [isWaitingListSubmitted, setWaitingListSubmitted] = useState<boolean>(false)
  const { region, loading } = useRegionContext()

  useEffect(() => {
    if (user?.email) {
      setEmail(user?.email)
    }
  }, [user])

  useEffect(() => {
    if (loading) return
    if (!loading && !REGIONS_WITH_FULL_BENEFITS.has(region) && !isDismissed.current) {
      setOpen(true)
    }
  }, [loading, region])

  const regionName = new Intl.DisplayNames([locale ? locale : 'en'], { type: 'region' }).of(
    !region || region === 'N/A' ? 'US' : region,
  )

  const onClose = useCallback(() => {
    setOpen(false)
    isDismissed.current = true
  }, [])

  if (slug === slugs.forKingAndCountryADrummerBoyChristmasLive && region === 'AU') return null

  return (
    <ThemeContextProvider isDarkMode={false}>
      <AngelDialog
        open={isOpen}
        className="w-[700px] max-w-full sm:max-w-[700px]"
        title={t('thankYouForYourInterest', 'Thank You for Your Interest')}
        onClose={onClose}
      >
        <Switch>
          <Case condition={isWaitingListSubmitted}>
            <ParagraphSM color="core-gray-950" weight="medium" className="mb-4">
              <Translate i18nKey="youAreOnTheWaitingList" t={t}>
                Thank you for joining the waiting list! We will send you an email with instructions on how to vote when
                space becomes available.
              </Translate>
            </ParagraphSM>

            <Button
              type="submit"
              variant="lightgray"
              className="ml-auto w-full py-3 sm:order-1 sm:w-fit sm:px-10"
              onClick={onClose}
            >
              <Translate i18nKey="close" t={t}>
                Close
              </Translate>
            </Button>
          </Case>
          <Default>
            <ParagraphSM color="core-gray-950" weight="medium" className="mb-4">
              <Translate i18nKey="someBenefitsAreOnlyAvailableInLimitedRegionsV1" t={t} values={{ regionName }}>
                It appears you are visiting from <span className="font-bold text-red">{{ regionName }}</span>.
                Complimentary tickets for Guild members as well as Early Access to some of our shows is not available to
                Guild members in your country at this time.
              </Translate>
            </ParagraphSM>

            <ParagraphSM color="core-gray-950" weight="medium" className="mb-4">
              <Translate i18nKey="joinTheBeta" t={t}>
                If you would like to join the waiting list for our Beta program to allow you to vote on the shows we
                make next, enter your email below.
              </Translate>
            </ParagraphSM>

            <EmailCaptureFormInput
              position=""
              placeholder={t('enterYourEmailAddress', 'Enter Your Email Address')}
              inputValue={email}
              setInputValue={setEmail}
              projectSlug="beta-guild-voting"
              customTrackPayload={{
                region,
                regionName,
                locale,
              }}
              inputWrapperClassName="flex-col"
              inputClassName="border rounded-lg border-core-gray-600 py-2 pl-3 w-full mb-4"
              onSubmit={() => setWaitingListSubmitted(true)}
              disableSubscribe
            >
              <Button
                type="submit"
                variant="oxide"
                className="ml-auto w-full py-3 sm:order-1 sm:w-fit sm:px-10"
                disabled={Boolean(!email || email.length < 4)}
              >
                <Translate i18nKey="joinTheWaitingList" t={t}>
                  Join the Waiting List
                </Translate>
              </Button>
            </EmailCaptureFormInput>
          </Default>
        </Switch>
      </AngelDialog>
    </ThemeContextProvider>
  )
}
