import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import LazyHydrate from 'react-lazy-hydration'
import { Image } from '@/atoms/Image'
import { AsH1, AsSpan, HeadingMD, ParagraphMD } from '@/atoms/Text'
import { isGuildSignupSimpleLayout } from '@/services/GuildSignupPageService/validations'
import { GuildSignupSimpleLayoutParagraph } from '@/types/codegen-contentful'
import { GuildEmailInput } from '@/views/GuildJoinView/GuildEmailInput'
import { useGuildSignupContext } from '@/views/GuildSignupView/GuildSignupContext'

interface GuildSimpleHeroProps {
  hasUser: boolean
}

export const GuildSimpleHero: React.FC<GuildSimpleHeroProps> = ({ hasUser }) => {
  const { layout, buttonColor } = useGuildSignupContext()

  if (!isGuildSignupSimpleLayout(layout)) return null
  const { heading, paragraph, primaryImage } = layout
  const image = primaryImage?.[0]

  return (
    <section aria-label={heading as string}>
      <div className="relative pt-16">
        <div className="flex items-center bg-transparent">
          <div className="flex w-full flex-col items-center justify-end p-4 sm:p-10 lg:flex-row se:p-2">
            <div className="flex w-full flex-1 items-center justify-center">
              <div className="flex max-w-lg flex-col justify-center gap-y-6">
                <div className="flex flex-col gap-y-8">
                  <Image
                    src="/v1700075329/angel-studios/guild/Version_Guild_Angel_Copper_OnLight_Yes.webp"
                    height={120}
                    width={96}
                    alt="Angel Guild"
                    className="shrink-0"
                  />
                  <div className="flex flex-col gap-y-4">
                    <HeadingMD as={AsH1} weight="bold" className="se:photon-heading-sm sm:photon-heading-xl">
                      {heading}
                    </HeadingMD>
                    <ParagraphMD as={AsSpan} weight="semibold" color="core-gray-800" className="se:photon-paragraph-sm">
                      {documentToReactComponents((paragraph as GuildSignupSimpleLayoutParagraph).json)}
                    </ParagraphMD>
                  </div>
                </div>
                <div className="flex w-full max-w-[600px] flex-col">
                  <LazyHydrate whenVisible>
                    <GuildEmailInput hasUser={hasUser} buttonColor={buttonColor} />
                  </LazyHydrate>
                </div>
              </div>
            </div>
            <div className="max-w-3xl flex-1">
              <Image
                priority
                width={image.width}
                height={image.height}
                alt=""
                src={`/w_800/v${image.version}/${image.public_id}.webp`}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
