import React from 'react'
import classNames from 'classnames'
import { Else, If, Then, When } from 'react-if'
import { LinkButton } from '@/atoms/Button'
import { GuildCopperIcon } from '@/atoms/Icons/GuildCopperIcon'
import { ResponsiveBackgroundImage } from '@/atoms/ResponsiveBackgroundImage'
import { AsP, CaptionMD, LabelSM, TitleMD } from '@/atoms/Text'
import { paths } from '@/constants'
import { GuildBenefitListNew } from '@/molecules/GuildBenefitList'
import { GuildPricingLabel } from '@/molecules/GuildPricingLabel'
import { GuildSignupButtonNew } from '@/molecules/GuildSignupButton'
import { useGuildUser } from '@/services/GuildUserService'
import { GuildPlanSelectionNew } from '@/utils/GuildUtil/GuildUtil'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { useGuildSignupContext } from '@/views/GuildSignupView/GuildSignupContext'

export interface GuildPlanBoxProps extends GuildPlanSelectionNew {
  className?: string
  offerId: string
  monthlyOfferId: string
  orderClass?: string
  planInterval?: 'monthly' | 'annual'
  punchy?: boolean
  title: string
  isQuote?: boolean
  hidePremiumBenefits?: boolean
}

export const GuildPlanBoxTiers: React.FC<GuildPlanBoxProps> = ({
  className,
  currency = 'USD',
  monthlyOfferId,
  offerId,
  orderClass,
  price,
  monthlyPrice,
  tier,
  planInterval = 'annual',
  title,
  punchy,
  isQuote,
  hidePremiumBenefits,
}) => {
  const { t } = useTranslate('guild')
  const { isGuildMember } = useGuildUser()
  const { sale } = useGuildSignupContext()
  const planId = planInterval === 'annual' ? offerId : monthlyOfferId
  const promotion = sale?.promotions.find((p) => p.offer.id === planId)

  const isPunchyAnnualPremium = punchy && planInterval === 'annual' && tier === 'premium'

  return (
    <div
      className={classNames('relative p-1 rounded-2xl', orderClass, className, { 'bg-black': isPunchyAnnualPremium })}
    >
      <div
        className={classNames(
          'relative w-full h-full rounded-2xl bg-white text-core-gray-950 overflow-hidden md:flex flex-col text-left',
        )}
      >
        <div className="relative flex items-center justify-between gap-2 px-6 py-2">
          <ResponsiveBackgroundImage
            src={
              tier === 'basic'
                ? '/v1714422036/angel-studios/guild/card_banner-free'
                : '/v1714422036/angel-studios/guild/card_banner-paid'
            }
            responsiveAR={{
              xs: 4.2875,
              sm: 8,
              md: 8,
              lg: 4,
              xl: 4,
              '2xl': 4,
            }}
            gravity="g_east"
          />
          <h3 className="relative">
            <TitleMD as={AsP} weight="bold" className="!leading-none">
              {title}
            </TitleMD>
          </h3>
          <GuildCopperIcon size={39} className="relative" />
        </div>
        <div className="flex flex-col gap-3 p-6">
          <GuildBenefitListNew
            interval={planInterval}
            tier={tier}
            checkColor="core-gray-950"
            hideWhenNotAvailable={tier === 'basic' && hidePremiumBenefits}
          />
        </div>
        <div className="mt-auto px-6 pb-5">
          <GuildPricingLabel
            tier={tier}
            currency={currency}
            price={price}
            monthlyPrice={monthlyPrice}
            planInterval={planInterval}
            offerId={planId}
          />

          <If condition={isQuote}>
            <Then>
              <LinkButton
                href={paths.account.guildMembershipQuote(planInterval === 'annual' ? offerId : monthlyOfferId)}
                variant="core-gray-900"
                className="w-full"
              >
                <LabelSM className="capitalize">
                  <Translate t={t} i18nKey="selectPlan">
                    Select Plan
                  </Translate>
                </LabelSM>
              </LinkButton>
            </Then>
            <Else>
              <GuildSignupButtonNew
                currency={currency}
                offerId={planId}
                price={price}
                promoCode={promotion ? promotion?.promotionCode.code : null}
                planInterval={planInterval}
              >
                <LabelSM className="capitalize">
                  <If condition={isGuildMember}>
                    <Then>
                      <Translate t={t} i18nKey="upgradeMembership">
                        Upgrade Membership
                      </Translate>
                    </Then>
                    <Else>
                      <Translate t={t} i18nKey="joinTheGuild">
                        Join the Guild
                      </Translate>
                    </Else>
                  </If>
                </LabelSM>
              </GuildSignupButtonNew>
            </Else>
          </If>
        </div>
      </div>
      <When condition={isPunchyAnnualPremium}>
        <div
          className={classNames('absolute rounded-full', {
            'bg-core-gray-900 px-3 py-1 -top-3 right-6': false,
            'bg-warm-01 px-6 py-2 -top-4 right-6': true,
          })}
        >
          <CaptionMD weight="bold" color="white">
            {sale?.abbreviatedPitch ?? t('bestValue', 'Best Value')}
          </CaptionMD>
        </div>
      </When>
    </div>
  )
}
