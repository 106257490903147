import React from 'react'
import classNames from 'classnames'
import { CircleCheckIcon } from '@/atoms/Icons/CircleCheckIcon'
import { IconColor } from '@/atoms/utils'
import { GuildPlanPerk } from '@/molecules/GuildPlanPerk'
import { useTranslate } from '@/utils/translate/translate-client'
import { CircleCloseIcon } from "@/atoms/Icons/CircleCloseIcon";

export type GuildBenefitListPropsv3 = {
  className?: string
  tier: 'basic' | 'premium'
  interval: 'monthly' | 'annual'
  slug?: string
  checkColor?: IconColor
  hideWhenNotAvailable?: boolean
}

export const GuildBenefitList: React.FC<GuildBenefitListPropsv3> = ({
  className,
  tier,
  checkColor = 'core-oxide',
  hideWhenNotAvailable = false,
}) => {
  const { t } = useTranslate('guild-benefits')

  const benefits = [
    {
      description: t('adFreeStreaming', 'Stream all of our shows Ad-Free.'),
      tiers: ['premium'],
      hideWhenNotAvailable: true,
    },
    {
      description: t('streamEverything', 'Stream all of our shows with limited ads.'),
      tiers: ['basic'],
      hideWhenNotAvailable: true,
    },
    {
      description: t('guildHelpsFundAngelOriginals', 'Help fund Angel Originals, speeding up new content releases.'),
      tiers: ['basic', 'premium'],
    },
    {
      description: t('voteOnShows', 'Vote on pilots, concepts, and feature films to decide what we make next.'),
      tiers: ['basic', 'premium'],
    },
    {
      description: t('joinLivestreams', 'Join exclusive livestreams including LIVE recordings of Dry Bar Comedy!'),
      tiers: ['basic', 'premium'],
    },
    {
      description: t(
        'earlyAccessToStreamAngelOriginals',
        'Early Access to stream our shows after their theatrical release.',
      ),
      tiers: ['basic', 'premium'],
    },
    {
      description: t(
        'twoTicketsToEveryReleasev2',
        'Two movie tickets to every Angel Studios theatrical release to use or give away.',
      ),
      tiers: ['premium'],
    },
    {
      description: t('discountOnMerchV1', 'Get 20% off merch in our Online Store.'),
      tiers: ['premium'],
    },
  ]

  return (
    <ul className={classNames('flex flex-col gap-4', className)}>
      <>
        {benefits.map((benefit) => {
          const hasBenefit = benefit.tiers.indexOf(tier) > -1
          if (!hasBenefit && (hideWhenNotAvailable || benefit.hideWhenNotAvailable)) return null
          return (
            <li key={benefit.description}>
              <GuildPlanPerk
                captionClassName={classNames('!max-w-none ml-2 max-w-[280px]', {
                  '!text-core-gray-400 line-through': !hasBenefit,
                })}
                icon={
                  hasBenefit ? (
                    <CircleCheckIcon size={15} color1={checkColor} className="flex-none" />
                  ) : (
                    <CircleCloseIcon size={15} color="core-gray-400" className="flex-none" />
                  )
                }
                description={benefit.description}
              />
            </li>
          )
        })}
      </>
    </ul>
  )
}
