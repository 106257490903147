import { NextApiResponse } from 'next'
import { NextRouter } from 'next/router'
import { TokenSetParameters } from '@/auth/session/TokenSet'
import { AUTH_JWT_COOKIE } from '@/constants/cookies'
import { appendCookiesToApiResponse } from '@/utils/next-api/next-response-utils'

type HandleSignUpFlowExperimentType = (
  e: React.MouseEvent<HTMLAnchorElement>,
  url: string,
  router: NextRouter,
  signUpFlowFeatureValue: string,
  showModalFunc: () => void,
) => void

export const handleSignUpFlowExperiment: HandleSignUpFlowExperimentType = (
  e,
  url,
  router,
  signUpFlowFeatureValue,
  showModalFunc,
) => {
  if (signUpFlowFeatureValue === 'modal') {
    e.preventDefault()
    showModalFunc()
    return
  }
  if (signUpFlowFeatureValue === 'nav') {
    e.preventDefault()
  }
  router.push(url)
}

export const setAngelJwt = (res: NextApiResponse, tokenResult: TokenSetParameters) => {
  appendCookiesToApiResponse(res, [
    `${AUTH_JWT_COOKIE}=${tokenResult.access_token}; Path=/; Max-Age=${tokenResult.expires_in}; Secure; SameSite=lax;`,
  ])
}

export const isTokenSetParameters = (tokenResult: unknown): tokenResult is TokenSetParameters => {
  return (
    !!tokenResult &&
    typeof tokenResult === 'object' &&
    'access_token' in tokenResult &&
    typeof tokenResult.access_token === 'string'
  )
}
