import { ApolloClient, gql } from '@apollo/client'
import { MarketProductTypeChoices } from '@/types/codegen-federation'
import { logger } from '@/utils/logging'
import { getWebClient } from '../ApolloClient'
import { Project } from '../ProjectsService'

/**
 * Note: This query is sharing types with the query below. Make sure both queries request the same fields.
 */
export const getOffersForProjectQuery = gql`
  query getOffersForProject($projectSlug: String!) {
    offers(projectSlug: $projectSlug) {
      id
      enabled
      currency
      name
      price
      slug
      allowMonthlySubscription
      region
      offeredproductSet {
        id
        name
        order
        quantity
        product {
          type
          pif
          id
        }
      }
    }
  }
`

/**
 * Note: This query is sharing types with the query above. Make sure both queries request the same fields.
 */
export const getOfferByIdQuery = gql`
  query getOfferById($offerId: ID!) {
    offer(id: $offerId) {
      id
      enabled
      currency
      region
      name
      price
      slug
      allowMonthlySubscription
      offeredproductSet {
        id
        name
        order
        quantity
        product {
          pif
          id
        }
      }
    }
  }
`

export const getProjectSupportersQuery = gql`
  query fetchProjectWallSupporters($input: FetchProjectSupportersInput!) {
    fetchProjectWallSupporters(input: $input) {
      activeSupporter {
        assignedBucket
        userUuid
        displayName
        userShowOptIn
      }
      buckets {
        oneThousandPlus {
          userUuid
          displayName
          userShowOptIn
        }
        upToFiveHundred {
          userUuid
          displayName
          userShowOptIn
        }
        upToOneThousand {
          userUuid
          displayName
          userShowOptIn
        }
      }
      totals {
        oneThousandPlus
        upToFiveHundred
        upToOneThousand
      }
    }
  }
`

export const updateContributorOptInMutation = gql`
  mutation updateProjectSupporter($input: UpdateProjectSupporterInput!) {
    updateProjectSupporter(input: $input) {
      userUuid
      displayName
      assignedBucket
      userShowOptIn
    }
  }
`

export const GET_PIF_PROJECTS = gql`
  query pifExplainer {
    projects {
      slug
      name
      pifEnabled
      pifExplainerVideoUrl
      discoveryPosterCloudinaryPath
      discoveryPosterTransformation
      logoCloudinaryPath
      sortOrder
      public
    }
  }
`

interface GetOffersForProjectRequest {
  projectSlug: string
}

interface GetOfferByIdRequest {
  projectSlug?: string
  offerId: string
}

// TODO use generated types. Backing out for now because time crunch and lots of downstream null checks will be required.
// export type Offer = NonNullable<NonNullable<NonNullable<GetOffersForProjectQuery>['offers']>[number]>
// export type Perk = NonNullable<NonNullable<NonNullable<Offer>['offeredproductSet']>[number]>

export interface Offer {
  id: string
  enabled: boolean
  currency: string
  region: string
  name: string
  price: number
  slug: string
  allowMonthlySubscription?: boolean
  offeredproductSet: Perk[]
}

export interface Perk {
  id: string
  name: string
  order: number
  quantity: number
  product: {
    pif: boolean
    id: string
    type?: MarketProductTypeChoices
  }
}

export const getOffersForProject = async (
  { projectSlug }: GetOffersForProjectRequest,
  client: ApolloClient<object>,
): Promise<Offer[]> => {
  const { data } = await client.query({
    query: getOffersForProjectQuery,
    variables: {
      projectSlug,
    },
    errorPolicy: 'all',
  })

  if (!data?.offers) return []

  return data.offers.filter((offer: Offer) => offer?.enabled)
}

export const getOfferById = async (
  { offerId }: GetOfferByIdRequest,
  client: ApolloClient<object>,
): Promise<Offer | null> => {
  const result = await client.query({
    query: getOfferByIdQuery,
    variables: {
      offerId,
    },
    errorPolicy: 'all',
  })

  if (result.error) {
    logger().error('An error occurred while attempting to retrieve an offer!', { offerId }, result.error)
    return null
  }

  const offer = result?.data?.offer
  if (!offer) return null

  logger().info('Successfully retrieved an offer.', { offer })
  return offer
}

export function getPeopleImpacted(offer: Offer): number {
  return offer?.offeredproductSet?.find((perk) => perk?.product?.pif)?.quantity ?? 0
}

export function getIndexForOfferThatImpactsClosestTo100People(offers: Offer[]) {
  const local = [...offers]
  local?.sort((a, b) => {
    return Math.abs(100 - getPeopleImpacted(a)) - Math.abs(100 - getPeopleImpacted(b))
  })

  return offers.findIndex((offer) => offer.id === local?.[0]?.id)
}

export const hasOffers = async ({ slug }: { slug: string }, client: ApolloClient<object>) => {
  const offers = await getOffersForProject({ projectSlug: slug }, client)

  return offers?.length > 0
}

const supportedPayPalCurrencies = [
  'AUD',
  'BRL',
  'CAD',
  'CNY',
  'CZK',
  'DKK',
  'EUR',
  'HKD',
  'HUF',
  'ILS',
  'JPY',
  'MYR',
  'MXN',
  'TWD',
  'NZD',
  'NOK',
  'PHP',
  'PLN',
  'GBP',
  'SGD',
  'SEK',
  'CHF',
  'THB',
  'USD',
]

const supportedStripeCountries = [
  'USD',
  'AED',
  'AFN',
  'ALL',
  'AMD',
  'ANG',
  'AOA',
  'ARS',
  'AUD',
  'AWG',
  'AZN',
  'BAM',
  'BBD',
  'BDT',
  'BGN',
  'BIF',
  'BMD',
  'BND',
  'BOB',
  'BRL',
  'BSD',
  'BWP',
  'BYN',
  'BZD',
  'CAD',
  'CDF',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CRC',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EGP',
  'ETB',
  'EUR',
  'FJD',
  'FKP',
  'GBP',
  'GEL',
  'GIP',
  'GMD',
  'GNF',
  'GTQ',
  'GYD',
  'HKD',
  'HNL',
  'HTG',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'ISK',
  'JMD',
  'JPY',
  'KES',
  'KGS',
  'KHR',
  'KMF',
  'KRW',
  'KYD',
  'KZT',
  'LAK',
  'LBP',
  'LKR',
  'LRD',
  'LSL',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MMK',
  'MNT',
  'MOP',
  'MRO',
  'MUR',
  'MVR',
  'MWK',
  'MXN',
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'PAB',
  'PEN',
  'PGK',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RUB',
  'RWF',
  'SAR',
  'SBD',
  'SCR',
  'SEK',
  'SGD',
  'SHP',
  'SLE',
  'SOS',
  'SRD',
  'STD',
  'SZL',
  'THB',
  'TJS',
  'TOP',
  'TRY',
  'TTD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'UYU',
  'UZS',
  'VND',
  'VUV',
  'WST',
  'XAF',
  'XCD',
  'XOF',
  'XPF',
  'YER',
  'ZAR',
  'ZMW',
]

export const isSupportedStripeCurrency = (currency: string) => {
  return supportedStripeCountries.includes(currency.toUpperCase())
}

export const isSupportedPayPalCurrency = (currency: string) => {
  return supportedPayPalCurrencies.includes(currency.toUpperCase())
}

export const getPifProjects = async (): Promise<Project[]> => {
  const client = getWebClient()

  try {
    const { data } = await client.query({
      query: GET_PIF_PROJECTS,
      errorPolicy: 'all',
    })
    return data?.projects ?? []
  } catch (error) {
    logger().warn(`Error fetching PIF projects`, error)
    return []
  }
}
