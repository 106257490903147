import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useRouter } from 'next/router'
import { useUser } from '@/services/UserService'
import { useSafeTrack, useSafeAnalytics } from '@/utils/analytics'
import { reportErrorToBugsnag } from '@/utils/bugsnag'
import { logger } from '@/utils/logging'

const subscribeToEmailGroupMutation = gql`
  mutation SubscribeToEmailGroup($input: SubscribeToEmailGroupInput!) {
    subscribeToEmailGroup(input: $input) {
      success
    }
  }
`

export function useCaptureOrderEmail() {
  const analytics = useSafeAnalytics()
  const { query } = useRouter()

  return useCallback(
    async function captureEmailAndSubscribe({
      subscriptionGroupId,
      email,
      position,
      projectSlug,
      ...customTrackPayload
    }: {
      subscriptionGroupId: string
      email: string
      position: string
      projectSlug: string
    } & Record<string, unknown>) {
      if (!email) {
        reportErrorToBugsnag('Email is required')
      }

      try {
        const user = analytics?.user?.()
        const userOrderEmail = user?.traits?.orderEmail
        if (email !== userOrderEmail) {
          await analytics.identify(user?.userId || null, {
            ...(user?.traits || {}),
            orderEmail: email,
          })
        }
      } catch (e) {
        logger().error('An error occurred while attempeting to identify a user for analytics', e)
        reportErrorToBugsnag(e instanceof Error ? e : new Error(JSON.stringify(e)))
      }

      await analytics.track('Email Captured', {
        slug: query.slug,
        position,
        subscriptionGroupId,
        email,
        projectSlug,
        ...customTrackPayload,
      })
    },
    [query.slug, analytics],
  )
}

export function useCaptureEmailAndSubscribe(disableSubscribe?: boolean) {
  const { userId } = useUser()
  const track = useSafeTrack()
  const { query } = useRouter()
  const [subscribeToEmailGroup] = useMutation(subscribeToEmailGroupMutation, {
    errorPolicy: 'all',
  })

  return useCallback(
    async function captureEmailAndSubscribe({
      subscriptionGroupId,
      email,
      position,
      projectSlug,
      ...customTrackPayload
    }: {
      subscriptionGroupId?: string
      email: string
      position: string
      projectSlug: string
    } & Record<string, unknown>) {
      if (!email) {
        reportErrorToBugsnag('Email is required')
      }
      if (!!subscriptionGroupId && !disableSubscribe) {
        reportErrorToBugsnag('subscriptionGroupId is required')
      }

      track('Email Captured', {
        slug: query.slug,
        position,
        subscriptionGroupId,
        email,
        projectSlug,
        ...customTrackPayload,
      })

      return !disableSubscribe
        ? subscribeToEmailGroup({
            variables: {
              input: {
                userId: userId ?? null,
                email,
                subscriptionGroupId,
                projectSlug,
              },
            },
          })
        : Promise.resolve()
    },
    [track, query.slug, subscribeToEmailGroup, userId, disableSubscribe],
  )
}
