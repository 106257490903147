import Stripe from 'stripe'
import { logger } from '@/utils/logging'
import { getValidString } from '@/utils/primitives/strings'

/*
 * Stripe API documentation: https://docs.stripe.com/api?lang=node
 *
 * NOTE: Error objects returned by the SDK are not standard Javascript Errors. They have several properties for context.
 * See https://docs.stripe.com/api/errors?lang=node
 */

/**
 * Returns a promotion code if a valid promotion code ID was provided. Otherwise returns undefined.
 * @param id
 */
export async function findPromotionCodeById(id: string): Promise<Stripe.PromotionCode | undefined> {
  try {
    const stripe = initialize()
    const promotionCode = await stripe.promotionCodes.retrieve(id)
    logger().info('Successfully retrieved a Stripe Promotion Code by id.', { id, promotionCode })
    return promotionCode
  } catch (err) {
    logger().error('An error occurred while attempting to find a Stripe Promotion Code by id.', { id, err })
  }
}

/**
 * Returns a promotion code if a valid customer facing code was provided. Otherwise returns undefined.
 * @param code
 */
export async function findPromotionCodeByCustomerFacingCode(code: string): Promise<Stripe.PromotionCode | undefined> {
  try {
    const stripe = initialize()
    const response = await stripe.promotionCodes.list({ code })
    const promotionCodes = response.data || []
    const promotionCode = promotionCodes[0]

    if (promotionCodes.length === 0) {
      logger().error(`Failed to find a Stripe Promotion Code by customer facing code.`, { code })
      return
    }
    if (promotionCodes.length > 1) {
      logger().warn(
        `Found multiple Promotion Codes for the given customer facing code. Check Stripe for duplicate codes or codes with partial matches. The first match will be returned.`,
        { code, promotionCodes },
      )
      return promotionCode
    }

    logger().info('Successfully retrieved a Stripe Promotion Code by customer facing code.', { code, promotionCode })
    return promotionCode
  } catch (err) {
    logger().error('An error occurred while attempting to find a Stripe Promotion Code by customer facing code.', {
      code,
      err,
    })
  }
}

function initialize(): Stripe {
  const apiKey = getValidString(process.env.STRIPE_WRITE_KEY)
  if (!apiKey) {
    throw new Error('A required API key STRIPE_WRITE_KEY is missing!')
  }

  return new Stripe(apiKey)
}
