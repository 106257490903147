import React, { useCallback, useState } from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import classNames from 'classnames'
import { Case, Switch, When } from 'react-if'
import { GuildIcon } from '@/atoms/Icons/GuildIcon'
import { PlayIcon } from '@/atoms/Icons/PlayIcon'
import { Image } from '@/atoms/Image'
import { ResponsiveBackgroundImage } from '@/atoms/ResponsiveBackgroundImage'
import { AsH2, AsSpan, HeadingXS, ParagraphLG, ParagraphMD, ParagraphSM } from '@/atoms/Text'
import { YoutubeVideoModal } from '@/molecules/YoutubeVideoModal/YoutubeVideoModal'
import { formatDimensions, formatTitleLogo } from '@/services/ContentCatalog'
import { options } from '@/services/ContentfulService'
import { GuildSignupExplainerLayout } from '@/types/codegen-contentful'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { GuildEmailInput } from '@/views/GuildJoinView/GuildEmailInput'
import { useGuildSignupContext } from '../GuildSignupContext'

interface GuildExplainerLayoutProps {
  guildExplainerLayout: GuildSignupExplainerLayout
  hasUser: boolean
  hideButton?: boolean
}

export const GuildExplainerLayout: React.FC<GuildExplainerLayoutProps> = ({
  guildExplainerLayout,
  hasUser,
  hideButton,
}) => {
  const [showModal, setShowModal] = useState(false)
  const { catalogTitle, logo, slug, buttonColor } = useGuildSignupContext()
  const { title, description, backgroundImageUrl, imagesCollection, videoUrl, disableSectionFade } =
    guildExplainerLayout
  const titleLogo = formatTitleLogo(catalogTitle)
  const canShowTitleLogo = Boolean(catalogTitle && titleLogo)
  const showVideo = Boolean(videoUrl)

  const handleModalClick = useCallback(() => {
    setShowModal(!showModal)
  }, [showModal])

  return guildExplainerLayout ? (
    <section
      id="guild-explainer"
      className="relative -mt-1 overflow-x-hidden text-pretty border-0 px-8 py-12 text-center text-white md:px-16 md:py-20 lg:px-12 lg:py-28 se:px-4"
      aria-label={title as string}
    >
      {backgroundImageUrl && <ResponsiveBackgroundImage src={backgroundImageUrl} />}
      <When condition={!disableSectionFade}>
        <div className="absolute inset-0 bg-gradient-to-t from-[#222222]" />
        <div className="absolute inset-0 bg-gradient-to-b from-[#222222]" />
      </When>
      <div className="relative flex w-full flex-col items-center gap-6">
        <div className="flex flex-row items-center justify-center gap-6">
          <GuildIcon color1={'white'} size={48} />
          <div className="max-w-[107px] md:max-w-[130px] md:p-0">
            <Switch>
              <Case condition={canShowTitleLogo}>
                {titleLogo && (
                  <Image
                    alt={'Guild Logo'}
                    src={getCloudinaryImageUrl({
                      path: titleLogo.cloudinaryPath,
                      transforms: 'e_trim',
                      ...formatDimensions([500, 'auto'], titleLogo),
                    })}
                    {...formatDimensions([500, 'auto'], titleLogo)}
                  />
                )}
              </Case>
              <Case condition={!canShowTitleLogo && Array.isArray(logo)}>
                <div className="max-w-[500px] p-[25px] md:p-0">
                  {Array.isArray(logo) && (
                    <Image
                      alt={title ?? 'Logo'}
                      src={getCloudinaryImageUrl({
                        path: logo[0]?.url ?? '',
                        transforms: 'e_trim',
                      })}
                      width={logo[0]?.width}
                      height={logo[0]?.height}
                    />
                  )}
                </div>
              </Case>
            </Switch>
          </div>
        </div>
        <HeadingXS
          as={AsH2}
          weight="bold"
          className={classNames('md:photon-heading-sm lg:photon-heading-md xl:photon-heading-lg', {
            'xl:max-w-[934px]': title && title.length > 70,
          })}
        >
          {title}
        </HeadingXS>
        {description?.json && (
          <ParagraphSM weight="medium" as={AsSpan} className="lg:photon-paragraph-md md:max-w-[672px] lg:max-w-[738px]">
            {documentToReactComponents(description.json, options)}
          </ParagraphSM>
        )}
        {imagesCollection && imagesCollection?.items.length > 2 && (
          <div className="relative my-6 flex flex-row items-center justify-center pt-12 md:my-8">
            <Image
              alt={imagesCollection.items[0]?.altText ?? ''}
              src={imagesCollection.items[0]?.item[0].secure_url}
              width={250}
              height={141}
              className={classNames(
                'rounded-xl drop-shadow-dark-3 xl:h-[238px] xl:w-[426px]',
                showVideo && 'hidden md:block',
              )}
            />
            <When condition={showVideo}>
              <div
                className="relative z-10 -mx-4 -mt-12 drop-shadow-dark-3 transition-all duration-[200ms] ease-in-out hover:cursor-pointer hover:drop-shadow-dark-5 md:-mx-12 xl:h-[360px] xl:w-[640px] se:mx-0"
                onClick={handleModalClick}
              >
                <Image
                  alt={imagesCollection.items[1]?.altText ?? ''}
                  src={imagesCollection.items[1]?.item[0].secure_url}
                  width={320}
                  height={180}
                  className="w-full rounded-xl"
                />
                <div className="absolute left-1/2 top-1/2 z-20 flex h-12 w-12 -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full bg-white/30 pl-1 backdrop-blur-md lg:h-24 lg:w-24">
                  <PlayIcon size={60} />
                </div>
              </div>
              <YoutubeVideoModal youTubeUrl={videoUrl} handleClose={handleModalClick} showModal={showModal} />
            </When>
            <When condition={!showVideo}>
              <Image
                alt={imagesCollection.items[1]?.altText ?? ''}
                src={imagesCollection.items[1]?.item[0].secure_url}
                width={250}
                height={141}
                className="z-10 -mx-12 -mt-12 rounded-xl drop-shadow-dark-3 xl:h-[238px] xl:w-[426px]"
              />
            </When>
            <Image
              alt={imagesCollection.items[2]?.altText ?? ''}
              src={imagesCollection.items[2]?.item[0].secure_url}
              width={250}
              height={141}
              className={classNames(
                'rounded-xl drop-shadow-dark-3 xl:h-[238px] xl:w-[426px]',
                showVideo && 'hidden md:block',
              )}
            />
          </div>
        )}

        <When condition={slug === 'bitcoin-and-the-beast'}>
          <ParagraphLG weight="bold" as={AsSpan} className="md:max-w-[672px] lg:max-w-[738px]">
            Tuttle Twins was the world’s first kids show to teach about Bitcoin - 40 million views and counting! Watch{' '}
            <i>Bitcoin and the Beast</i> now for FREE!
          </ParagraphLG>
        </When>

        {!hideButton && (
          <div className="flex min-h-[72px] w-full max-w-[600px] items-center justify-center">
            <GuildEmailInput hasUser={hasUser} isHero={true} buttonColor={buttonColor} />
          </div>
        )}

        <When condition={slug === 'bitcoin-and-the-beast'}>
          <ParagraphMD weight="medium" as={AsSpan} className="md:max-w-[672px] lg:max-w-[738px]">
            Guild members get access to dozens of Tuttle Twins episodes- new episodes every month!
          </ParagraphMD>
        </When>
      </div>
    </section>
  ) : null
}
